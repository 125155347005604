/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AIModelDTO } from './AIModelDTO';

export type WorkspaceDTO = {
    country: string;
    createdAt: string;
    description?: string;
    id: string;
    name: string;
    status: WorkspaceDTO.status;
    type: WorkspaceDTO.type;
    updatedAt?: string;
    phoneNumber?: string;
    email?: string;
    aiModels: Array<AIModelDTO>;
};

export namespace WorkspaceDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }

    export enum type {
        B2B = 'B2B',
        B2C = 'B2C',
    }


}

