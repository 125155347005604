import { Box, Text } from '@gluestack-ui/themed';
import { useCallback, useContext } from 'react';

import { TranslationContext } from '../../../contexts/translations/Translation';
import type { StrainDTO } from '../../../generated/client-api-client';
import { COLORS } from '../../../theme/colors';
import { pascalToSnake } from '../../../utils/StringUtils';

type IAnalysisDetailsTestTabProps = {
  listProperties: string[];
  pathogen: (StrainDTO & { uri: string }) | undefined;
  testID?: string;
};

const AnalysisDetailsTestTab = (props: IAnalysisDetailsTestTabProps) => {
  const { listProperties, pathogen, testID } = props;
  const { i18n } = useContext(TranslationContext);

  const keys: ResultKeys[] = Object.keys(pathogen ?? {}).filter((key: string) => listProperties.includes(key)) as ResultKeys[];

  const filterPathogen = useCallback((keys: ResultKeys[]) => {
    if(!pathogen) return keys;
    return keys.sort((a: ResultKeys, b: ResultKeys) => {
      const inBiochemicalTestNeededA = pathogen.biochemicalTestNeeded?.[a] || false;
    const inBiochemicalTestNeededB = pathogen.biochemicalTestNeeded?.[b] || false;

    const inPathogenA = pathogen[a] || false;
    const inPathogenB = pathogen[b] || false;

    const isAInBoth = inBiochemicalTestNeededA && inPathogenA;
    const isBInBoth = inBiochemicalTestNeededB && inPathogenB;

    const isAInBiochemicalOnly = inBiochemicalTestNeededA && !inPathogenA;
    const isBInBiochemicalOnly = inBiochemicalTestNeededB && !inPathogenB;

    const isAInPathogenOnly = inPathogenA && !inBiochemicalTestNeededA;
    const isBInPathogenOnly = inPathogenB && !inBiochemicalTestNeededB;

    // Order: both → biochemicalTestNeeded → pathogen → rest
    if (isAInBoth && !isBInBoth) return -1;
    if (!isAInBoth && isBInBoth) return 1;

    if (isAInBiochemicalOnly && !isBInBiochemicalOnly) return -1;
    if (!isAInBiochemicalOnly && isBInBiochemicalOnly) return 1;

    if (isAInPathogenOnly && !isBInPathogenOnly) return -1;
    if (!isAInPathogenOnly && isBInPathogenOnly) return 1;

    return 0;
  
      return 0;
    });
  }, [pathogen]);

  return (
    <Box bgColor={COLORS.NEUTRAL[0]} flex={1} display="flex" flexDirection="column" gap={20} flexWrap="nowrap" paddingVertical={30} paddingHorizontal={20} overflow="scroll" flexBasis={0}>
      {filterPathogen(keys).map((key: ResultKeys) => (
        <Box key={key} testID={`${testID ?? ''}${AnalysisDetailsTestTab.name}_item`}>
          <Text color={COLORS.NEUTRAL[80]}>
            {i18n?.t(`properties.${pascalToSnake(key)}`)}
            {pathogen?.biochemicalTestNeeded?.[key]?.toString() ? (
              <Text testID={`${testID ?? ''}${AnalysisDetailsTestTab.name}_confirmResult`} fontWeight="bold" fontSize={12} color={COLORS.FOUNDATION_TERCIARY_100}>
                {' '}
                {i18n?.t('page.pathogen-details.confirm_ai_result')}
              </Text>
            ) : (
              ''
            )}
          </Text>
          <Text style={{ borderBottomWidth: 1, borderBottomColor: COLORS.NEUTRAL[70] }} testID={`${testID ?? ''}${AnalysisDetailsTestTab.name}_applicableTest`}>
            {pathogen?.[key] ? i18n?.t(`page.pathogen-details.plusMinusNA.${pathogen?.[key]?.toString()}`) : pathogen?.[key]?.toString()}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
type ResultKeys = keyof (StrainDTO & { uri: string });
export default AnalysisDetailsTestTab;
